import React, {ReactElement} from "react";
import {RecordToStringFunction} from "react-admin";
import {ResolveDefaultRecordRepresentation} from "../components/misc/DefaultRecordRepresentation";

interface ResourceItem {
    list: React.ComponentType<any>;
    show?: React.ComponentType<any>;
    edit?: React.ComponentType<any>;
    create?: React.ComponentType<any>;
    icon?: React.ComponentType<any>;
    recordRepresentation?: ReactElement | RecordToStringFunction | string;
    options?: any;
}

interface FinalResourceItem {
    name: string;
    list: React.ComponentType<any>;
    show?: React.ComponentType<any>;
    edit?: React.ComponentType<any>;
    create?: React.ComponentType<any>;
    icon?: React.ComponentType<any>;
    recordRepresentation?: ReactElement | RecordToStringFunction | string;
    options?: any;
}

class ResourceManager {
    private resources: FinalResourceItem[] = [];

    addResource = (name: string, icon: React.ComponentType<any>, resource: ResourceItem) => {
        let final: FinalResourceItem
        final = resource as FinalResourceItem
        final.name = name
        final.icon = icon

        if (final.recordRepresentation === undefined) {
            final.recordRepresentation = (record: any) => { return ResolveDefaultRecordRepresentation(record, name) }
        }

        this.resources.push(final);
    }

    getResources = () => {
        return this.resources;
    }
}

export const resourceManager = new ResourceManager();