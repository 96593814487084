import {Call} from "./utils";
import BaseApiProvider from "./BaseApiProvider";

const EvcsApi = {
    ...BaseApiProvider,

    subscribe: async (cardId: any, code: any) => {
        return await Call("POST", "/card-preferences/subscribe", {
            cardId: cardId,
            code: code,
        })
            .then(() => {
                return true;
            })
        ;
    },

    send: async (code: any, data: any) => {
        return await Call("POST", `/notifications/${code}`, JSON.parse(data))
            .then(() => {
                return true;
            })
        ;
    },
};

export default EvcsApi;