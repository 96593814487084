import {
  Datagrid,
  List,
  TextField,
  TextInput,
  TopToolbar,
  FilterButton,
  ReferenceInput, ReferenceField,
} from "react-admin";
import React from "react";
import SendOutlined from "@mui/icons-material/SendOutlined";
import {resourceManager} from "../../helpers/Resources";
import {SendButton} from "../buttons/SendButton";
import {DateFieldForTimestamp} from "../fields/DateFieldForTimestamp";

const filters = [
  <TextInput source="cardId" />,
  <ReferenceInput source="cardPreferenceId" reference="card-preferences" />,
];

const Actions = () => (
    <TopToolbar>
      <FilterButton />
      <SendButton />
    </TopToolbar>
);

const listAction = () => (
    <List exporter={false} actions={<Actions />} filters={filters} >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
        <DateFieldForTimestamp source="createdAt" showTime={true} />
      <TextField source="code" />
      <ReferenceField source="cardPreferenceId" reference="card-preferences" />
    </Datagrid>
  </List>
);

resourceManager.addResource('notifications', SendOutlined,{
  list: listAction,
})
