import React, { useState } from 'react';
import {useNotify, Button} from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import {Api} from "../../provider/Api";
import SendOutlined from "@mui/icons-material/SendOutlined";

export const SendButton = () => {
    const [open, setOpen] = useState(false);
    const [code, setCode] = useState('S/P/F/T');
    const [data, setData] = useState('{"card_id":"test","station":{"id":"test","label": "test name"}}');
    const notify = useNotify();

    const handleClick = async () => {
        let success = false
        if (code === "S") {
            success = await Api.send("charging-started", data)
        } else if (code === "P") {
            success = await Api.send("charging-progress", data)
        } else if (code === "F") {
            success = await Api.send("charging-finished", data)
        } else if (code === "T") {
            success = await Api.send("charging-full-filled", data)
        } else {
            notify(`Invalid code: S for Started, P for Progress, F for Finish, T for Full-filled.`)
            return
        }
        if (success) {
            notify(`Notification sent.`)
        } else {
            notify(`Notification failed.`)
        }
    }

    const obj = JSON.parse(data)
    let text = data
    if (null !== obj) {
        text = JSON.stringify(obj, null, 2)
    }

    return (
        <>
            <Button onClick={() => setOpen(true)} endIcon={<SendOutlined />} label="Send..." />
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Send a notification
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>

                    <RadioGroup aria-label="async" name="code" onChange={(event) => {
                        if (event.target.value === "S") {
                            setData('{"card_id":"test","station":{"id":"test","label": "test name"}}')
                        } else if (event.target.value === "P") {
                            setData('{ "card_id": "test", "progress": 0.8, "lifecycle_id": "lifecycle-test", "station": { "id": "station-test", "label": "The station name" }, "eta": 0 }')
                        } else if (event.target.value === "F") {
                            setData('{ "card_id": "test", "progress": 1, "lifecycle_id": "lifecycle-test", "station": { "id": "station-test", "label": "The station name" }}')
                        } else if (event.target.value === "T") {
                            setData('{ "card_id": "test", "lifecycle_id": "lifecycle-test", "station": { "id": "station-test", "label": "The station name" }, "report": {"id": "report-test"}}')
                        }
                        setCode(event.target.value)
                    }}>
                        <FormControlLabel value="S" control={<Radio />} label="ev.charging.started" />
                        <FormControlLabel value="P" control={<Radio />} label="ev.charging.progress" />
                        <FormControlLabel value="F" control={<Radio />} label="ev.charging.finished" />
                        <FormControlLabel value="T" control={<Radio />} label="ev.charging.fulfilled" />
                    </RadioGroup>

                    <TextField variant={"outlined"} label="Data" value={text} onChange={(event) => setData(event.target.value)} multiline fullWidth />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} label="Cancel"></Button>
                    <Button onClick={handleClick} label="Send"></Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
