import React from "react";
import {SendButton} from "./buttons/SendButton";

export const Dashboard = ({ permissions }: any) => {
  return (
      <>
        {permissions?.includes("admin") && <SendButton />}
      </>
  );
};
