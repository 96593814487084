import { Admin, Resource } from "react-admin";
import { Api } from "./provider/Api";
import { Dashboard } from "./components/Dashboard";
import './components/components';
import {resourceManager} from "./helpers/Resources";
import WkTheme from "./theme/Theme";
import {authProvider} from './provider/auth/authProvider';
import {GetHost} from "./provider/utils";
import {useEffect} from "react";

const App = () => {

    useEffect(() => {
        authProvider.getPermissions(null).then(permissions => {
            if (!permissions.includes('admin') && permissions.length > 0) {
                authProvider.logout(null)
            }
        });
    }, []);

    return (
        <>
            <Admin dataProvider={Api}
                   authProvider={authProvider}
                   dashboard={Dashboard}
                   loginPage={<></>}
                   theme={WkTheme}
                   disableTelemetry={true}
            >
                {permissions => (
                    <>
                        {permissions?.includes('admin') ? (resourceManager.getResources().map((resource) => (
                            <Resource
                                recordRepresentation={resource.recordRepresentation}
                                key={resource.name}
                                name={resource.name}
                                list={resource.list}
                                show={resource.show}
                                edit={resource.edit}
                                create={resource.create}
                                icon={resource.icon}
                            />
                        ))) : null}
                    </>
                )}
            </Admin>
            <div style={{
                position: 'fixed', right: 0, bottom: 0, left: 0, zIndex: 100,
                fontSize: 9,
                padding: 6,
                backgroundColor: 'transparent',
                textAlign: 'center',
            }}><a href={`https://swagger-${GetHost()}`} target="_blank" rel="noreferrer" style={{color: '#AAA'}}>Open API Definition</a></div>
        </>
    );
};

export default App;