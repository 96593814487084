import React, { useState } from 'react';
import {useRecordContext, useRefresh, useNotify, Button} from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';
import {Api} from "../../provider/Api";
import MailOutlined from "@mui/icons-material/MailOutlined";

export const SubscribeButton = () => {
    const [open, setOpen] = useState(false);
    const [code, setCode] = useState('');
    const record = useRecordContext();
    const refresh = useRefresh();
    const notify = useNotify();

    const handleClick = async () => {
        const success = await Api.subscribe(record.cardId, code)
        if (success) {
            notify(`Subscribed successfully.`)
            refresh()
        } else {
            notify(`Subscribed failed.`)
        }
    }

    return (
        <>
            <Button onClick={() => setOpen(true)} endIcon={<MailOutlined />} label="Subscribe..." />
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Subscription</DialogTitle>
                <DialogContent>
                    <TextField variant={"outlined"} label="Code" value={code} onChange={(event) => setCode(event.target.value)} />

                    <div>ev.charging.started</div>
                    <div>ev.charging.progress</div>
                    <div>ev.charging.finished</div>
                    <div>ev.charging.fulfilled</div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} label="Cancel"></Button>
                    <Button onClick={handleClick} label="Subscribe"></Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
